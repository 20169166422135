import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'
import {
  ActivityTemplate, AIFeederItem,
  Section,
  Template,
  Widget, WidgetMobilePosition, WidgetPosition
} from '@/core/helpers/template/TemplateInterface'
import store from '@/store'

export interface NewTemplatePayload {
    title: string,
    description: string,
    objective: string,
    image?: string,
    isPublished?: boolean,
    duration?: number,
    status?: number,
    templateJson: string,
    cluesJson: string,
    solutionJson: string,
    creator: string,
    updater?: string,
    deletedAt?: string
}

export interface ActivityTemplateFilter {
    filter: {
        limit?: number,
        offset?: number
        title?: string,
        status?: number,
        isPublished?: boolean,
        orderBy?: {
            [key: string]: string
        }
    }
}

export interface TemplateLocator {
    activeSectionIndex: number;
    activeWidgetIndex: number;
}

export interface UploadProgress {
    [key: string]: number;
}

export function findCommonElements (firstArray, secondArray) {
  if (firstArray.length === 0) {
    return true
  }
  return firstArray.some(item => secondArray.includes(item))
}

@Module
export default class TemplateModule extends VuexModule {
    // Activity Template specific properties
    activityTemplate = {}
    activityTemplates = [] as Array<ActivityTemplate>
    latestTemplates = [] as Array<ActivityTemplate>
    activityTemplatesCount = 0;
    allTemplatesCount = 0;
    readyTemplatesCount = 0;
    draftTemplatesCount = 0;
    fileUploadProgress = [] as Array<UploadProgress>
    tags = [] as any;
    // Template specific properties
    template = {
      templateUuid: '' as string,
      title: '' as string,
      description: '' as string,
      sections: [{
        sectionUuid: '' as string,
        title: '' as string,
        widgets: [] as Array<Widget>
      }] as Array<Section>,
      aiFeeder: [] as Array<AIFeederItem>
    } as Template

    solvingMode = false as boolean
    activeSectionIndex = 0 as number
    activeWidgetIndex = 0 as number
    activityTemplatesRequestPending = false;
    newlyAddedWidgets = [] as Array<string>;
    showGridLines = false;
    mobileViewMode = false;
    mobileShowHiddenWidgets = false;
    filterByCompany = null as any;
    filterByPublic = null as any;
    filterByKeyword = null as any;
    usedTags = [] as any;
    activeWidgetUuid = -1 as any
    activeWidgets = [] as any
    expandedHotspotWidgets = [] as any
    widgetsToCopy = [] as any
    copiedWidget = '' as any
    groupedWidgets = [] as any
    selectedWidgetScene = [] as any
    selectedHotspotInScene = [] as any
    templateModels = [] as any;
    hotspotSelectMode = false as boolean;
    hotspotWidgetInSelectMode = -1 as any
    removedWidgetsFromHidden:any = {}
    pointOutWidgets = [] as any
    regenerateSectionInSelectMode = false as boolean;
    disabledWidgets = [] as any

    get getDisabledWidgets () {
      return this.disabledWidgets
    }

    get isWidgetDisabled (): (widgetUuid: string) => boolean {
      const _this = this
      return function (widgetUuid: string): boolean {
        return _this.disabledWidgets.includes(widgetUuid)
      }
    }

    get getRegenerateSectionInSelectMode () {
      return this.regenerateSectionInSelectMode
    }

    get getRemovedWidgetsFromHidden () {
      return this.removedWidgetsFromHidden
    }

    get getActiveWidgetUuid () {
      return this.activeWidgetUuid
    }

    get isHotspotInSelectMode () {
      return this.hotspotSelectMode
    }

    get getHotspotWidgetInSelectMode () {
      return this.hotspotWidgetInSelectMode
    }

    get copiedWidgetUuid () {
      return this.copiedWidget
    }

    get getActiveWidgets () {
      return this.activeWidgets
    }

    get getExpandedHotspotWidgets () {
      return this.expandedHotspotWidgets
    }

    get getGroupedWidgets () {
      return this.groupedWidgets
    }

    get getWidgetsToCopy () {
      return this.widgetsToCopy
    }

    get getActivityTemplate () {
      return this.activityTemplate
    }

    /**
     * Get the api request status for activity templates
     * @returns boolean
     */
    get getActivityTemplatesRequestPending (): boolean {
      return this.activityTemplatesRequestPending
    }

    get getPointOutWidgets (): any {
      return this.pointOutWidgets
    }

    /**
     * Get the list of activity templates filtered
     * @returns array
     */
    get getActivityTemplates (): Array<ActivityTemplate> {
      return this.activityTemplates
    }

    get getActivityTemplatesCount (): number {
      return this.activityTemplatesCount
    }

    get getLatestTemplates (): Array<ActivityTemplate> {
      return this.latestTemplates
    }

    get getActivityTemplatesFiltered (): Array<ActivityTemplate> {
      return this.activityTemplates.filter((activityTemplate) => (((activityTemplate.visibility === this.filterByPublic ?? this.filterByPublic) || (activityTemplate.visibility === this.filterByCompany ?? this.filterByCompany)) && (activityTemplate.title.toLowerCase().indexOf(this.filterByKeyword !== null ? this.filterByKeyword.toLowerCase() : activityTemplate.title.toLowerCase()) !== -1) && findCommonElements(this.usedTags, JSON.parse(activityTemplate.tags))))
    }

    get getTagsAvailable () {
      return this.tags
    }

    /**
     * Get template
     * @returns Template
     */
    get getTemplate (): Template {
      return this.template
    }

    /**
     * Get template solving mode state
     * @returns boolean
     */
    get getSolvingMode (): boolean {
      return this.solvingMode
    }

    /**
     * Get template mobile view mode state
     * @returns boolean
     */
    get getMobileViewMode (): boolean {
      return this.mobileViewMode
    }

    /**
     * Get template mobile view reveal hidden widgets state
     * @returns boolean
     */
    get getRevealMobileHiddenWidgets (): boolean {
      return this.mobileShowHiddenWidgets
    }

    /**
     * Get sections
     * @returns Array<Section>
     */
    get getSections (): Array<Section> {
      return this.template.sections
    }

    /**
     * Get active section
     * @returns Section
     */
    get getActiveSection (): Section {
      return this.template.sections[this.activeSectionIndex]
    }

    /**
     * Get active widget
     * @returns Widget
     */
    get getActiveWidget (): Widget {
      return this.template.sections[this.activeSectionIndex]?.widgets[this.activeWidgetIndex]
    }

    /**
     * Get active section widgets positions
     * @returns Array<WidgetPosition>
     */
    get getActiveSectionWidgetsLayout (): Array<WidgetPosition> {
      const widgets = this.template.sections[this.activeSectionIndex].widgets
      const widgetsLayout = [] as Array<WidgetPosition>
      widgets.forEach((widget) => {
        const widgetPosition = widget.position as WidgetPosition
        widgetPosition.i = widget.widgetUuid
        widgetsLayout.push(widgetPosition)
      })
      return widgetsLayout
    }

    /**
     * Get active section widgets
     * @returns Array<Widget>
     */
    get getActiveSectionWidgets (): Array<Widget> {
      return this.template.sections[this.activeSectionIndex].widgets
    }

    get getActiveSectionRegenerateSection () {
      return this.template.sections[this.activeSectionIndex].regenerateSection ?? []
    }

    /**
     * Get active section widgets mobile position
     * @returns Array<WidgetMobilePosition>
     */
    get getActiveSectionWidgetsMobilePosition (): Array<WidgetMobilePosition> {
      if ( // cope with old templates that did not have mobile position
        this.template.sections[this.activeSectionIndex].widgetsMobilePosition === undefined ||
            this.template.sections[this.activeSectionIndex].widgetsMobilePosition.length === 0
      ) {
        this.template.sections[this.activeSectionIndex].widgetsMobilePosition = []
        this.template.sections[this.activeSectionIndex].widgets.forEach((widget) => {
          this.template.sections[this.activeSectionIndex].widgetsMobilePosition.push({ widgetUuid: widget.widgetUuid })
        })
      }

      return this.template.sections[this.activeSectionIndex].widgetsMobilePosition
    }

    /**
     * Get newly added widgets - used in adding animation when dropping a widget
     * @returns Array<string>
     */
    get getNewlyAddedWidgets (): Array<string> {
      return this.newlyAddedWidgets
    }

    /**
     * Get grid lines visibility state
     * @returns boolean
     */
    get getShowGridLines (): boolean {
      return this.showGridLines
    }

    /**
     * Get active section index
     * @returns number
     */
    get getActiveSectionIndex (): number {
      return this.activeSectionIndex
    }

    /**
     * Get active widget index
     * @returns number
     */
    get getActiveWidgetIndex (): number {
      return this.activeWidgetIndex
    }

    /**
     * Get a widget by UUID
     * @returns Widget
     */

    get getHiddenHotspotWidgets () {
      const hiddenWidgetsUuids:any = []
      const _this = this
      this.template.sections.forEach(sections => {
        sections.widgets.forEach(widget => {
          if (widget.type === 'hotspot-circle' || widget.type === 'hotspot-square' || widget.type === 'hotspot-triangle') {
            widget.specific.selectedWidgets.forEach((selectedWidgetUuid, i) => {
              if (!hiddenWidgetsUuids.includes(selectedWidgetUuid)) {
                if (_this.removedWidgetsFromHidden[widget.widgetUuid] !== undefined) {
                  if (!_this.removedWidgetsFromHidden[widget.widgetUuid].includes(selectedWidgetUuid)) {
                    hiddenWidgetsUuids.push(selectedWidgetUuid)
                  } else {
                    return
                  }
                }
                hiddenWidgetsUuids.push(selectedWidgetUuid)
              }
            })
          }
        })
      })
      return hiddenWidgetsUuids
    }

    get getAiFeederItems () {
      return this.template.aiFeeder
    }

    get getWidgetByUuid (): (widgetUuid: string) => Widget | [] {
      const widgetMap = new Map<string, Widget>()
      this.template.sections.forEach(section => {
        section.widgets.forEach(widget => {
          widgetMap.set(widget.widgetUuid, widget)
        })
      })

      return (widgetUuid: string) => widgetMap.get(widgetUuid) ?? []
    }

    /**
     * Get a section by UUID
     * @returns Section
     */

    get getSectionByUuid () {
      const _this = this
      return function (sectionUuid: string): Section | undefined {
        return _this.template.sections.find((section: Section) => section.sectionUuid === sectionUuid)
      }
    }

    /**
     * Get a widget index by UUID
     * @returns number
     */
    get getWidgetIndexByUuid (): (widgetUuid: string) => number | null {
      const _this = this
      return function (widgetUuid: string): number | null {
        for (let s = 0; s < _this.template.sections.length; s++) {
          const section = _this.template.sections[s]
          for (let w = 0; w < section.widgets.length; w++) {
            if (section.widgets[w].widgetUuid === widgetUuid) {
              return w
            }
          }
        }
        return null
      }
    }

    get getSelectedWidgetsForWidget (): (widgetUuid:any) => any {
      const _this = this
      return function (widgetUuid: any): any {
        if (widgetUuid === -1) {
          return []
        }
        if (widgetUuid === 'dummy') {
          return []
        }
        for (let s = 0; s < _this.template.sections.length; s++) {
          const section = _this.template.sections[s]
          for (let w = 0; w < section.widgets.length; w++) {
            if (section.widgets[w].widgetUuid === widgetUuid) {
              return section.widgets[w].specific.selectedWidgets
            }
          }
        }
        return []
      }
    }

    get getAllTemplatesCount (): number {
      return this.allTemplatesCount
    }

    get getReadyTemplatesCount (): number {
      return this.readyTemplatesCount
    }

    get getDraftTemplatesCount (): number {
      return this.draftTemplatesCount
    }

    // get getFileUploadProgress (): number {
    //   return this.fileUploadProgress
    // }

    get getHotspotsFor360ImageScene (): (widgetUuid: string, sceneUuid: string) => object {
      const _this = this
      return function (widgetUuid: string, sceneUuid: string): any {
        const widget = _this.template.sections[_this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === widgetUuid)
        if (widget) {
          const scene = widget.specific.scenes.find((scene) => scene.sceneUuid === sceneUuid)
          if (scene) {
            return scene.hotspots
          }
        }
      }
    }

    get getScenesFor360ImageWidget (): (widgetUuid: string) => object {
      const _this = this
      return function (widgetUuid: string): any {
        const widget = _this.template.sections[_this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === widgetUuid)
        const widgetIndex = _this.template.sections[_this.activeSectionIndex].widgets.findIndex((widget) => widget.widgetUuid === widgetUuid)
        if (widget) {
          return _this.template.sections[_this.activeSectionIndex].widgets[widgetIndex].specific.scenes
        }
      }
    }

    get getScenesExceptSelectedFor360ImageWidget (): (widgetUuid: string, selectedScene) => object {
      const _this = this
      return function (widgetUuid: string, selectedScene): any {
        const widget = _this.template.sections[_this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === widgetUuid)
        if (widget) {
          if (selectedScene) {
            return widget.specific.scenes.filter((scene) => scene.sceneUuid !== selectedScene.sceneUuid)
          }
          return widget.specific.scenes
        }
      }
    }

    get getSelectedSceneForWidget (): (widgetUuid: string) => string | null {
      const _this = this
      return function (widgetUuid: string): any {
        if (typeof _this.selectedWidgetScene[widgetUuid] !== 'undefined' && _this.selectedWidgetScene[widgetUuid] !== null) {
          return _this.selectedWidgetScene[widgetUuid]
        } else {
          const widget = _this.template.sections[_this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === widgetUuid)
          if (widget) {
            if (widget.specific.scenes.length >= 0) {
              return widget.specific.scenes[0]
            } else {
              return null
            }
          }
        }
      }
    }

    get getSelectedHotspotForScene (): (sceneUuid: string) => object {
      const _this = this
      return function (sceneUuid: string): any {
        if (typeof _this.selectedHotspotInScene[sceneUuid] !== 'undefined' && _this.selectedHotspotInScene[sceneUuid] !== null) {
          return _this.selectedHotspotInScene[sceneUuid]
        } else {
          return []
        }
      }
    }

    get getFileUploadProgressForWidget (): (widgetUuid: string) => number {
      const _this = this
      return function (widgetUuid: string): number {
        return _this.fileUploadProgress[widgetUuid] as number
      }
    }

    get getUploadedFilesForFileUploadWidget (): (widgetUuid: string) => object {
      const _this = this
      return function (widgetUuid: string): any {
        const widget = _this.template.sections[_this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === widgetUuid)
        if (widget) {
          return widget.specific.files
        }
      }
    }

    get getAllTemplateModels () {
      return this.templateModels
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_TEMPLATE] (templateUuid) {
      const response = await ApiService.get('api/v1/activity-template/' + templateUuid)
      const template = response.data.payload.templateJson
      this.context.commit(Mutations.SET_ACTIVITY_TEMPLATE, response.data.payload)
      console.log('Template JSON String:')
      console.log(JSON.stringify(template))
      console.table('Template JSON: ', template)
      return { template }
    }

    @Mutation
    [Mutations.UPDATE_REGENERATE_SECTION_IN_SELECT_MODE] (flag: boolean) {
      this.regenerateSectionInSelectMode = flag
    }

    @Mutation
    [Mutations.ADD_AI_FEEDER_ITEMS] (aiFeederItems: any) {
      this.template.aiFeeder = aiFeederItems
    }

    @Mutation
    [Mutations.ADD_WIDGET_TO_COPY] (widgetUuid) {
      if (!this.widgetsToCopy.includes(widgetUuid)) {
        console.log('adding widget to copy', widgetUuid)
        this.widgetsToCopy.push(widgetUuid)
      }
    }

    @Mutation
    [Mutations.REMOVE_WIDGET_FROM_COPY] (widgetUuid) {
      this.widgetsToCopy = this.widgetsToCopy.filter((widgetToCopyUuid) => widgetToCopyUuid !== widgetUuid)
    }

    @Mutation
    [Mutations.RESET_WIDGETS_TO_COPY] () {
      console.log('resting widgets to copy')
      this.widgetsToCopy = []
    }

    @Mutation
    [Mutations.SET_COPIED_WIDGET] (widgetUuid) {
      this.copiedWidget = widgetUuid
    }

    @Mutation
    [Mutations.RESET_COPIED_WIDGET] () {
      this.copiedWidget = ''
    }

    @Mutation
    [Mutations.ADD_ACTIVE_WIDGETS] (activeWidgetUuid) {
      if (!this.activeWidgets.includes(activeWidgetUuid)) {
        this.activeWidgets.push(activeWidgetUuid)
      }
    }

    @Mutation
    [Mutations.ADD_DISABLED_WIDGET] (widgetUuid) {
      if (!this.disabledWidgets.includes(widgetUuid)) {
        console.log('adding to disabled widgets', widgetUuid)
        this.disabledWidgets.push(widgetUuid)
      }
    }

    @Mutation
    [Mutations.CLEAR_DISABLED_WIDGETS] () {
      console.log('clearning disabled widgets')
      this.disabledWidgets = []
    }

    @Mutation
    [Mutations.RESET_ACTIVE_WIDGETS] () {
      this.activeWidgets = []
    }

    @Mutation
    [Mutations.REMOVE_ACTIVE_WIDGET] (widgetUuid) {
      this.activeWidgets = this.activeWidgets.filter((activeWidgetUuid) => activeWidgetUuid !== widgetUuid)
    }

    @Mutation
    [Mutations.SET_ACTIVITY_TEMPLATE] (activityTemplate) {
      this.activityTemplate = activityTemplate
    }

    @Mutation
    [Mutations.SET_FILTER_USED_TAGS] (usedTags) {
      this.usedTags = usedTags
    }

    @Mutation
    [Mutations.SET_COMPANY_VISIBILITY_ON_ACTIVITY_TEMPLATES] (companyId) {
      this.filterByCompany = companyId
    }

    @Mutation
    [Mutations.SET_PUBLIC_VISIBILITY_ON_ACTIVITY_TEMPLATES] (visibility) {
      this.filterByPublic = visibility
    }

    @Mutation
    [Mutations.SET_SEARCH_BY_KEYWORD_FILTER] (keyword) {
      this.filterByKeyword = keyword
    }

    @Mutation
    [Mutations.UPDATE_ACTIVITY_TEMPLATE_IN_ACTIVITY_TEMPLATES] (activityTemplate) {
      const activityTemplateInActivityTemplates = this.activityTemplates.find((at) => at.activityTemplateUuid === activityTemplate.activityTemplateUuid) as any
      if (activityTemplateInActivityTemplates) {
        const newActivityTemplate = activityTemplateInActivityTemplates as any
        if (activityTemplateInActivityTemplates.status === 1) {
          newActivityTemplate.status = 2
        } else {
          newActivityTemplate.status = 1
        }
        Object.assign(activityTemplateInActivityTemplates, newActivityTemplate)
      }
    }

    @Mutation
    [Mutations.UPDATE_ACTIVITY_TEMPLATE_IN_LATEST_TEMPLATES] (activityTemplate) {
      const activityTemplateInActivityTemplates = this.latestTemplates.find((at) => at.activityTemplateUuid === activityTemplate.activityTemplateUuid) as any
      if (activityTemplateInActivityTemplates) {
        const newActivityTemplate = activityTemplateInActivityTemplates as any
        if (activityTemplateInActivityTemplates.status === 1) {
          newActivityTemplate.status = 2
        } else {
          newActivityTemplate.status = 1
        }
        Object.assign(activityTemplateInActivityTemplates, newActivityTemplate)
      }
    }

    @Mutation
    [Mutations.ADD_WIDGET_TO_GROUP] (widgetUuid) {
      if (!this.groupedWidgets.includes(widgetUuid)) {
        const widgetIndex = this.template.sections[this.activeSectionIndex].widgets.findIndex(w => w.widgetUuid === widgetUuid)
        const widget = this.template.sections[this.activeSectionIndex].widgets[widgetIndex]

        this.groupedWidgets.push({ widgetUuid: widgetUuid, initialX: widget?.position.x, initialY: widget?.position.y })
        // recalculate all others grouped widgets initial position in case they were selected in a different batch and move previously
        this.groupedWidgets.forEach((gw) => {
          const widgetIndex = this.template.sections[this.activeSectionIndex].widgets.findIndex(w => w.widgetUuid === gw.widgetUuid)
          const widget = this.template.sections[this.activeSectionIndex].widgets[widgetIndex]
          gw.initialX = widget?.position.x
          gw.initialY = widget?.position.y
        })
      }
    }

    @Mutation
    [Mutations.REMOVE_WIDGET_FROM_GROUP] (widgetUuid) {
      this.groupedWidgets = this.groupedWidgets.filter((groupedWidget) => groupedWidget.widgetUuid !== widgetUuid)
    }

    @Mutation
    [Mutations.ADD_POINT_OUT_WIDGET] (widgetUuid) {
      this.pointOutWidgets = []
      this.pointOutWidgets.push(widgetUuid)
    }

    @Mutation
    [Mutations.CLEAR_POINT_OUT_WIDGET] () {
      this.pointOutWidgets = []
    }

    @Mutation
    [Mutations.CLEAR_GROUPED_WIDGETS] () {
      this.groupedWidgets = []
    }

    @Mutation
    [Mutations.UPDATE_TEMPLATE_MODELS] (models) {
      this.templateModels = models
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES] (payload) {
      this.context.commit(Mutations.API_SET_ACTIVITY_TEMPLATES_REQUEST_PENDING, true)
      const response = await ApiService.get('api/v1/activity-template', '', payload)
      if (response.status === 200) {
        this.context.commit(Mutations.API_SET_ACTIVITY_TEMPLATES_REQUEST_PENDING, false)
      }
      const activityTemplates = response.data.payload
      return { activityTemplates }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_LATEST_ACTIVITY_TEMPLATES] (payload) {
      const response = await ApiService.get('api/v1/activity-template', '', payload)
      const latestTemplates = response.data.payload
      return { latestTemplates }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT] (payload) {
      const response = await ApiService.get('api/v1/activity-template', 'count', payload)
      const activityTemplatesCount = response.data.payload.count
      return { activityTemplatesCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_READY_TEMPLATES_COUNT] (payload) {
      const response = await ApiService.get('api/v1/activity-template', 'count', payload)
      const readyTemplatesCount = response.data.payload.count
      return { readyTemplatesCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_DRAFT_TEMPLATES_COUNT] (payload) {
      const response = await ApiService.get('api/v1/activity-template', 'count', payload)
      const draftTemplatesCount = response.data.payload.count
      return { draftTemplatesCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ALL_TEMPLATES_COUNT] (payload) {
      const response = await ApiService.get('api/v1/activity-template', 'count', payload)
      const allTemplatesCount = response.data.payload.count
      return { allTemplatesCount }
    }

    @Action({ rawError: true })
    async [Actions.API_CREATE_ACTIVITY_TEMPLATE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_CREATE_ACTIVITY_TEMPLATE_FROM_PROMPT] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/openai/ai-creator/template/prompt/create', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_360_WIDGET_CREATE_SKYBOX_IMAGE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/template/image360/generate', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_DUPLICATE_TEMPLATE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + payload.templateUuid + '/duplicate', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_GET_TEMPLATE_MODELS] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/template/models', payload)
          .then(({ data }) => {
            this.context.commit(Mutations.UPDATE_TEMPLATE_MODELS, data.payload.models)
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action
    async [Actions.API_DOWNLOAD_TEMPLATE] (payload) {
      const url = process.env.VUE_APP_API_URL + `/api/v1/activity-template/${payload.activityTemplateUuid}/download`

      // Instead of making an Axios request, use window.open to handle download
      // const fullUrl = `${url}?token=${encodeURIComponent('your-jwt-token-here')}`; // JWT appended to URL

      // Open the URL in a new window to trigger download immediately
      window.open(url, '_blank')

      // const response = await ApiService.get('api/v1/activity-template/' + payload.activityTemplateUuid + '/download', '', payload, { responseType: 'blob' })
      // // Download file
      // const blob = new Blob([response.data], { type: response.headers['content-type'] })
      // const href = URL.createObjectURL(blob)
      // const link = document.createElement('a')
      // link.href = href
      // console.log('response.headers', response)
      // let filename = 'download.zip' // Default fallback
      // const contentDisposition = response.headers['content-disposition']
      // if (contentDisposition && contentDisposition.includes('filename=')) {
      //   // Use a regular expression to extract the filename
      //   const matches = contentDisposition.match(/filename="?([^"]+)"?/)
      //   if (matches != null && matches[1]) {
      //     filename = matches[1]
      //   }
      // }
      // link.setAttribute('download', filename)
      // document.body.appendChild(link)
      // link.click()
      // // Clean download
      // document.body.removeChild(link)
      // URL.revokeObjectURL(href)
      // const config = {
      //   responseType: 'blob',
      //   onDownloadProgress: (progressEvent) => {
      //     if (progressEvent.lengthComputable) {
      //       const percentComplete = (progressEvent.loaded / progressEvent.total) * 100
      //       console.log(`Download progress: ${percentComplete.toFixed(2)}%`)
      //       // You can update a progress bar or some UI element here
      //     }
      //   }
      // }
      // try {
      //   const url = `api/v1/activity-template/${payload.activityTemplateUuid}/download`
      //   // Create a dummy link to trigger the download immediately
      //   const links = document.createElement('a')
      //   links.href = url
      //
      //   // Set the Authorization header manually since the link click won't include headers
      //   links.setAttribute('download', '')
      //   links.setAttribute('target', '_blank') // Ensure it's opened in a new context
      //   document.body.appendChild(links)
      //   links.click() // This triggers the save dialog immediately
      //   const response = await ApiService.get('api/v1/activity-template/' + payload.activityTemplateUuid + '/download', '', payload, config)
      //
      //   // Handle the file download
      //   const blob = new Blob([response.data], { type: response.headers['content-type'] })
      //   const href = URL.createObjectURL(blob)
      //   const link = document.createElement('a')
      //   link.href = href
      //
      //   // Extract the filename from the Content-Disposition header, if available
      //   let filename = 'download.zip' // Default fallback
      //   const contentDisposition = response.headers['content-disposition']
      //   if (contentDisposition && contentDisposition.includes('filename=')) {
      //     const matches = contentDisposition.match(/filename="?([^"]+)"?/)
      //     if (matches != null && matches[1]) {
      //       filename = matches[1]
      //     }
      //   }
      //
      //   link.setAttribute('download', filename)
      //   document.body.appendChild(link)
      //   link.click()
      //
      //   // Clean up
      //   document.body.removeChild(link)
      //   URL.revokeObjectURL(href)
      // } catch (error) {
      //   console.error('Error downloading file:', error)
      // }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_AVAILABLE_TAGS] () {
      const response = await ApiService.get('api/v1/activity-template', 'tags')
      const tags = response.data.payload
      return { tags }
    }

    @Action({ rawError: true })
    async [Actions.API_UPLOAD_ACTIVITY_TEMPLATE_IMAGE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + payload.templateUuid + '/image', payload.data, payload.config)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UPLOAD_FILE_ACTIVITY_TEMPLATE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + payload.templateUuid + '/file', payload.data, payload.config)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UPDATE_ACTIVITY_TEMPLATE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + payload.templateUuid, payload.data, payload.config)
          .then(({ data }) => {
            console.log('should resolve', data)
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_IMPORT_TEMPLATE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/import', payload.data, payload.config)
          .then(({ data }) => {
            console.log('should resolve', data)
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_PROCESS_TEXT_TO_SPEECH] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + payload.templateUuid + '/widget/' + payload.widgetUuid + '/text-to-speech', {})
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_PUBLISH_TEMPLATE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + payload.templateUuid + '/publish', payload)
          .then(({ data }) => {
            this.context.commit(Mutations.UPDATE_LOCAL_TEMPLATE, data.payload)
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_MAKE_READY_TEMPLATE] (templateUuid) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + templateUuid + '/ready', templateUuid)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_MAKE_DRAFT_TEMPLATE] (templateUuid) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + templateUuid + '/draft', templateUuid)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_REGENERATE_WIDGET] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + payload.templateUuid + '/widget/' + payload.widgetUuid + '/regenerate', payload.data)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_REGENERATE_SECTION] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + payload.templateUuid + '/section/' + payload.sectionUuid + '/regenerate', payload.data)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_DELETE_ACTIVITY_TEMPLATE] (templateUuid) {
      return new Promise((resolve, reject) => {
        ApiService.delete('api/v1/activity-template/' + templateUuid)
          .then(({ data }) => {
            resolve(data)
            this.context.commit(Mutations.REMOVE_ACTIVITY_TEMPLATE, templateUuid)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_DELETE_ACTIVITY_TEMPLATE_ATTACHMENT] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.delete('api/v1/activity-template/' + payload.templateUuid + '/file/' + payload.attachmentUuid)
          .then(({ data }) => {
            resolve(data)
            this.context.commit(Mutations.REMOVE_ACTIVITY_TEMPLATE_ATTACHMENT, data.payload)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Mutation
    [Mutations.REMOVE_ACTIVITY_TEMPLATE_ATTACHMENT] (payload) {
      if (payload.associatedWidget !== null) {
        const widget = this.template.sections[this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === payload.associatedWidget)
        if (widget) {
          switch (widget.type) {
            case 'image-card':
              if (widget.specific.image === payload.associatedWidget) {
                widget.specific.image = null
              }
              break
            case 'file-upload':
              if (widget.specific.files.length) {
                widget.specific.files.forEach((item, key, array) => {
                  if (item.attachmentUuid === payload.attachmentUuid) {
                    widget.specific.files.splice(key, 1)
                  }
                })
              }

              break
            default:
              break
          }
        }
      }
    }

    @Mutation
    [Mutations.UPDATE_LOCAL_TEMPLATE] (template) {
      const existingTemplate = this.activityTemplates.find(t => t.activityTemplateUuid === template.activityTemplateUuid)
      if (existingTemplate) {
        Object.assign(existingTemplate, template)
      }
    }

    @Mutation
    [Mutations.SET_ACTIVITY_TEMPLATE_SOLVING_MODE] (solvingMode) {
      this.solvingMode = solvingMode
    }

    @Mutation
    [Mutations.SET_ACTIVITY_TEMPLATE_SHOW_GRID_LINES] (showGridLines) {
      this.showGridLines = showGridLines
    }

    @Mutation
    [Mutations.SET_ACTIVITY_TEMPLATE_MOBILE_VIEW_MODE] (mobileViewMode) {
      this.mobileViewMode = mobileViewMode
    }

    @Mutation
    [Mutations.SET_ACTIVITY_TEMPLATE_REVEAL_MOBILE_HIDDEN_WIDGETS] (revealMobileHiddenWidgets) {
      this.mobileShowHiddenWidgets = revealMobileHiddenWidgets
    }

    @Mutation
    [Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX] (sectionIndex) {
      this.activeSectionIndex = sectionIndex
      this.newlyAddedWidgets = []
      this.groupedWidgets = [] // clear the grouped widgets array
    }

    @Mutation
    [Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX] (widgetIndex) {
      this.activeWidgetIndex = widgetIndex
    }

    @Mutation
    [Mutations.SET_TEMPLATE_ACTIVE_WIDGET_UUID] (widgetUuid) {
      this.activeWidgetUuid = widgetUuid
    }

    @Mutation
    [Mutations.SET_TEMPLATE_ADD_SECTION] (newSection) {
      this.template.sections.push(newSection)
      this.activeSectionIndex = this.template.sections.length - 1
    }

    @Mutation
    [Mutations.SET_TEMPLATE_EDIT_SECTION] (payload) {
      if (payload.section.settings) {
        this.template.sections[payload.sectionIndex].settings = payload.section.settings
      }
      // if (payload.section.title) {
      //   this.template.sections[payload.sectionIndex].title = payload.section.title
      // }
    }

    @Mutation
    [Mutations.UPDATE_WIDGET_EDIT_HOTSPOT] (payload) {
      const widget = this.template.sections[this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === payload.widgetUuid)
      if (widget) {
        // Find the scene that contains the hotspot
        const sceneIndex = widget.specific.scenes.findIndex(scene => scene.hotspots.some(hotspot => hotspot.hotspotUuid === payload.hotspotUuid))

        if (sceneIndex !== -1) {
          const scene = widget.specific.scenes[sceneIndex]
          const hotspotIndex = scene.hotspots.findIndex((hotspot) => hotspot.hotspotUuid === payload.hotspot.hotspotUuid)

          if (hotspotIndex !== -1) {
            scene.hotspots[hotspotIndex] = payload.hotspot
          }
        }
      }
    }

    @Mutation
    [Mutations.SET_TEMPLATE_REMOVE_SECTION] (sectionIndex) {
      this.template.sections.splice(sectionIndex, 1)
      if (this.activeSectionIndex >= sectionIndex && sectionIndex !== 0) {
        this.activeSectionIndex--
      }
    }

    @Mutation
    [Mutations.SET_TEMPLATE_ADD_WIDGET] (payload) {
      this.template.sections[this.activeSectionIndex].widgets.push(payload.widget)
      this.template.sections[this.activeSectionIndex].widgetsMobilePosition.push({ widgetUuid: payload.widget.widgetUuid })

      // we push the widget UUID into the newly added array to execute the drop widget animation
      this.newlyAddedWidgets.push(payload.widget.widgetUuid)
      this.template.sections[this.activeSectionIndex].regenerateSection.push({
        widgetUuid: payload.widget.widgetUuid,
        enabled: false,
        prompt: ''
      })
    }

    @Mutation
    [Mutations.SET_TEMPLATE_EDIT_WIDGET] (payload) {
      // this.template.sections[payload.activeSectionIndex].widgets[payload.activeWidgetIndex] = payload.widget
    }

    @Mutation
    [Mutations.SET_TEMPLATE_REMOVE_WIDGET_BY_UUID] (widgetUuid) {
      this.template.sections[this.activeSectionIndex].widgets = this.template.sections[this.activeSectionIndex].widgets.filter(widget => widget.widgetUuid !== widgetUuid)
      this.template.sections[this.activeSectionIndex].widgetsMobilePosition = this.template.sections[this.activeSectionIndex].widgetsMobilePosition.filter(widgetMobPos => widgetMobPos.widgetUuid !== widgetUuid)
      this.template.sections[this.activeSectionIndex].regenerateSection = this.template.sections[this.activeSectionIndex].regenerateSection.filter(widget => widget.widgetUuid !== widgetUuid)
    }

    @Mutation
    [Mutations.UPDATE_REGENERATE_WIDGETS_LIST] (regenerateList) {
      this.template.sections[this.activeSectionIndex].regenerateSection = regenerateList
    }

    @Mutation
    [Mutations.SET_TEMPLATE_WIDGET_ORDER_CHANGED] (params) {
      if (params.toFront) {
        this.template.sections[this.activeSectionIndex].widgets.push(
          this.template.sections[this.activeSectionIndex].widgets.splice(params.widgetIndex, 1)[0]
        )
      } else {
        this.template.sections[this.activeSectionIndex].widgets.unshift(
          this.template.sections[this.activeSectionIndex].widgets.splice(params.widgetIndex, 1)[0]
        )
      }
    }

    @Mutation
    [Mutations.API_SET_ACTIVITY_TEMPLATES_REQUEST_PENDING] (value) {
      this.activityTemplatesRequestPending = value
    }

    @Mutation
    [Mutations.INCREASE_READY_TEMPLATES_COUNTER] (byNumber: number) {
      this.readyTemplatesCount = this.readyTemplatesCount + byNumber
    }

    @Mutation
    [Mutations.DECREASE_READY_TEMPLATES_COUNTER] (byNumber: number) {
      this.readyTemplatesCount = this.readyTemplatesCount - byNumber
    }

    @Mutation
    [Mutations.INCREASE_DRAFT_TEMPLATES_COUNTER] (byNumber: number) {
      this.draftTemplatesCount = this.draftTemplatesCount + byNumber
    }

    @Mutation
    [Mutations.DECREASE_DRAFT_TEMPLATES_COUNTER] (byNumber: number) {
      this.draftTemplatesCount = this.draftTemplatesCount - byNumber
    }

    @Mutation
    [Mutations.REMOVE_ACTIVITY_TEMPLATE] (activityUuid) {
      let removedActivity = {} as any
      for (let i = 0; i < this.activityTemplates.length; i++) {
        if (this.activityTemplates[i].activityTemplateUuid === activityUuid) {
          removedActivity = this.activityTemplates[i]
          this.activityTemplates.splice(i, 1)
        }
      }

      this.allTemplatesCount = this.allTemplatesCount - 1
      this.activityTemplatesCount = this.activityTemplatesCount - 1

      if (removedActivity.status === 2) {
        this.readyTemplatesCount = this.readyTemplatesCount - 1
      }

      if (removedActivity.status === 1) {
        this.draftTemplatesCount = this.draftTemplatesCount - 1
      }
    }

    @Mutation
    [Mutations.ADD_NEW_TEMPLATE_TO_ALL_TEMPLATES] (newTemplate) {
      this.activityTemplates.push(newTemplate)
      this.allTemplatesCount++
      this.draftTemplatesCount++
    }

    @Mutation
    [Mutations.UPDATE_FILE_UPLOAD_PROGRESS] (payload) {
      this.fileUploadProgress[payload.widgetUuid] = payload.progress
    }

    @Mutation
    [Mutations.UPDATE_WIDGET_SET_IMAGE] (payload) {
      const widget = this.template.sections[this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === payload.widgetUuid)
      if (widget) {
        widget.specific.image = payload.attachmentMeta
      }
    }

    @Mutation
    [Mutations.UPDATE_SECTION_SET_IMAGE] (payload) {
      const section = this.template.sections.find((section) => section.sectionUuid === payload.sectionUuid)
      if (section) {
        section.backgroundImage = payload.attachmentMeta
      }
    }

    @Mutation
    [Mutations.UPDATE_WIDGET_ADD_SCENE] (payload) {
      const widget = this.template.sections[this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === payload.widgetUuid)
      const widgetIndex = this.template.sections[this.activeSectionIndex].widgets.findIndex((widget) => widget.widgetUuid === payload.widgetUuid)
      if (widget) {
        const newScene = {} as any
        newScene.sceneUuid = payload.sceneUuid
        newScene.image = payload.attachmentMeta
        newScene.hotspots = []
        this.template.sections[this.activeSectionIndex].widgets[widgetIndex].specific.scenes.push(newScene)
        // widget.specific.scenes.push(newScene)
        const selectedScene = {
          widgetUuid: payload.widgetUuid,
          scene: newScene
        }
        store.commit(Mutations.SET_SELECTED_SCENE_FOR_WIDGET, selectedScene)
      }
    }

    @Mutation
    [Mutations.UPDATE_WIDGET_ADD_HOTSPOT_TO_SCENE] (payload) {
      const widget = this.template.sections[this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === payload.widgetUuid)
      if (widget) {
        const scene = widget.specific.scenes.find((scene) => scene.sceneUuid === payload.sceneUuid)

        if (scene) {
          const hotspotExists = scene.hotspots.some((hotspot) => hotspot.hotspotUuid === payload.hotspot.hotspotUuid)

          if (!hotspotExists) {
            scene.hotspots.push(payload.hotspot)
          }
        }

        const selectedHotspot = {
          sceneUuid: payload.sceneUuid,
          hotspot: payload.hotspot
        }
        store.commit(Mutations.SET_SELECTED_HOTSPOT_IN_SCENE, selectedHotspot)
      }
    }

    @Mutation
    [Mutations.UPDATE_WIDGET_REMOVE_HOTSPOT] (payload) {
      const widget = this.template.sections[this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === payload.widgetUuid)
      if (widget) {
        // Find the scene that contains the hotspot
        const sceneIndex = widget.specific.scenes.findIndex(scene => scene.hotspots.some(hotspot => hotspot.hotspotUuid === payload.hotspotUuid))

        if (sceneIndex !== -1) {
          const scene = widget.specific.scenes[sceneIndex]

          // Remove the hotspot from the scene
          scene.hotspots = scene.hotspots.filter(hotspot => hotspot.hotspotUuid !== payload.hotspotUuid)
        }
      }
    }

    @Mutation
    [Mutations.UPDATE_WIDGET_REMOVE_SCENE] (payload) {
      let sectionIndex = -1
      this.template.sections.forEach((section, index) => {
        section.widgets.forEach((widget) => {
          if (widget.widgetUuid === payload.widgetUuid) {
            sectionIndex = index
          }
        })
      })
      const widget = this.template.sections[sectionIndex].widgets.find((widget) => widget.widgetUuid === payload.widgetUuid)
      const widgetIndex = this.template.sections[sectionIndex].widgets.findIndex((widget) => widget.widgetUuid === payload.widgetUuid)
      console.log('sectionIndex', sectionIndex)
      console.log('widgetIndex', widgetIndex)
      console.log('widget', widget)
      if (widget) {
        console.log('count scenes', this.template.sections[sectionIndex].widgets[widgetIndex].specific.scenes.length)

        this.template.sections[sectionIndex].widgets[widgetIndex].specific.scenes = this.template.sections[sectionIndex].widgets[widgetIndex].specific.scenes.filter(scene => scene.sceneUuid !== payload.sceneUuid)
        console.log('count scenes after', this.template.sections[sectionIndex].widgets[widgetIndex].specific.scenes.length)

        // widget.specific.scenes = widget.specific.scenes.filter(scene => scene.sceneUuid !== payload.sceneUuid)
        if (this.template.sections[sectionIndex].widgets[widgetIndex].specific.scenes.length) {
          const selectedScene = {
            widgetUuid: payload.widgetUuid,
            scene: this.template.sections[sectionIndex].widgets[widgetIndex].specific.scenes[0]
          }
          store.commit(Mutations.SET_SELECTED_SCENE_FOR_WIDGET, selectedScene)
        } else {
          this.selectedWidgetScene = []
        }

        // Find the scene that contains the hotspot
        // const sceneIndex = widget.specific.scenes.findIndex(scene => scene.hotspots.some(hotspot => hotspot.hotspotUuid === payload.hotspotUuid))
        //
        // if (sceneIndex !== -1) {
        //   const scene = widget.specific.scenes[sceneIndex]
        //
        //   // Remove the hotspot from the scene
        //   scene.hotspots = scene.hotspots.filter(hotspot => hotspot.hotspotUuid !== payload.hotspotUuid)
        // }
      }
    }

    @Mutation
    [Mutations.UPDATE_WIDGET_EDIT_WIDGET] (newWidget) {
      for (const section of this.template.sections) {
        const widget = section.widgets.find((widget) => widget.widgetUuid === newWidget.widgetUuid)
        if (widget) {
          Object.assign(widget, newWidget)
          // You can choose to break the loop here if you only want to update the first matching widget.
          // break;
        }
      }
    }

    @Mutation
    [Mutations.UPDATE_WIDGET_EDIT_WIDGET_STREAM] (payload) {
      for (const section of this.template.sections) {
        const widget = section.widgets.find((widget) => widget.widgetUuid === payload.widgetUuid)
        if (widget) {
          const oldWidget = widget
          switch (widget.type) {
            case 'text-card-styled':
              oldWidget.specific.content = payload.content.specific.content
              Object.assign(widget, oldWidget)
              break
            case 'heading':
              oldWidget.specific.content = payload.content.specific.content
              Object.assign(widget, oldWidget)
              break
            case 'question-single-choice':
            case 'question-team-readiness':
            case 'question-multiple-choice':
              oldWidget.specific.question = payload.content.specific.question
              oldWidget.specific.answersList = payload.content.specific.answersList
              oldWidget.specific.solution = payload.content.specific.solution
              oldWidget.specific.explanation = payload.content.specific.explanation
              Object.assign(widget, oldWidget)

              break
            case 'question-short-answer':
            case 'question-short-answer-ai-scored':
              oldWidget.specific.question = payload.content.specific.question
              oldWidget.specific.solution = payload.content.specific.solution
              oldWidget.specific.explanation = payload.content.specific.explanation
              break
            case 'drag-and-drop-group':
            case 'drag-and-drop-order':
              oldWidget.specific.itemList = payload.content.specific.itemList
              oldWidget.specific.columnList = payload.content.specific.columnList
              oldWidget.specific.solution = payload.content.specific.solution
              oldWidget.specific.itemListTitle = payload.content.specific.itemListTitle
              oldWidget.specific.explanation = payload.content.specific.explanation
              break
          }
          // const oldWidget = widget
          // oldWidget.specific.content = payload.content
          // Object.assign(widget, oldWidget)
          // You can choose to break the loop here if you only want to update the first matching widget.
          break
        }
      }
    }

    @Mutation
    [Mutations.SET_SELECTED_HOTSPOT_IN_SCENE] (payload) {
      this.selectedHotspotInScene[payload.sceneUuid] = payload.hotspot
    }

    @Mutation
    [Mutations.SET_SELECTED_SCENE_FOR_WIDGET] (payload) {
      this.selectedWidgetScene[payload.widgetUuid] = payload.scene
    }

    @Mutation
    [Mutations.UPDATE_WIDGET_ADD_FILE] (payload) {
      const widget = this.template.sections[this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === payload.widgetUuid)
      if (widget) {
        widget.specific.files.push(payload.attachmentMeta)
      }
    }

    @Mutation
    [Mutations.ADD_HOTSPOT_EXPANDED_WIDGETS] (widgetUuid) {
      this.expandedHotspotWidgets.push(widgetUuid)
    }

    @Mutation
    [Mutations.REMOVE_HOTSPOT_EXPANDED_WIDGETS] (widgetUuid) {
      this.expandedHotspotWidgets = this.expandedHotspotWidgets.filter((expandedWidgetUuid) => expandedWidgetUuid !== widgetUuid)
    }

    @Mutation
    [Mutations.UPDATE_IS_HOTSPOT_IN_SELECT_MODE] (flag: boolean) {
      this.hotspotSelectMode = flag
    }

    @Mutation
    [Mutations.UPDATE_HOTSPOT_WIDGET_IN_SELECT_MODE] (widgetUuid:any = -1) {
      this.hotspotWidgetInSelectMode = widgetUuid
    }

    @Mutation
    [Mutations.REMOVE_WIDGETS_FROM_SELECTED_HOTSPOTS] (widgetUuid: any) {
      const _this = this
      this.template.sections.forEach((section, iSection) => {
        section.widgets.forEach((widget, iWidget) => {
          if (widgetUuid === widget.widgetUuid) {
            _this.removedWidgetsFromHidden[widgetUuid] = widget.specific.selectedWidgets
            _this.template.sections[iSection].widgets[iWidget].specific.selectedWidgets = []
          }
        })
      })
    }

    @Mutation
    [Mutations.ADD_WIDGETS_TO_SELECTED_HOTSPOTS] (widgetUuid: any) {
      const _this = this
      this.template.sections.forEach(section => {
        section.widgets.forEach(widget => {
          if (widgetUuid === widget.widgetUuid) {
            // _this.removedWidgetsFromHidden[widgetUuid] = widget.specific.selectedWidgets
            if (_this.removedWidgetsFromHidden[widgetUuid] !== undefined) {
              widget.specific.selectedWidgets = _this.removedWidgetsFromHidden[widgetUuid]
              _this.removedWidgetsFromHidden = {}
            }
          }
        })
      })
    }
}
