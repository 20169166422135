import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

export interface LearningPathSettings {
    allowNotifyManagers: number,
    allowMultipleAnswers: number,
    allowPreIntroScreen: number,
    isInClassroom: boolean,
    isFacilitated: boolean
}

export interface DeployMetadata {
    employees?: Array<object>;
    teams?: Array<object>;
    activities?: Array<object>;
}

export interface LearningPath {
    uuid: string;
    title: string;
    description: string;
    objective: string;
    status: number;
    settings: LearningPathSettings;
    deployMetadata: DeployMetadata;
    scheduledAt?: string;
    facilitators?: string;
    createdAt: string;
    updatedAt: string;
}

export interface NewLearningPath {
    title: string;
    description: string;
    objective: string;
    settings?: LearningPathSettings;
}

export interface UpdateLearningPath {
    title?: string;
    description?: string;
    objective?: string;
    scheduledAt?: string;
    facilitators?: string;
    settings?: LearningPathSettings
}

@Module
export default class LearningPathModule extends VuexModule {
    learningPath = {} as LearningPath
    learningPaths = [] as Array<LearningPath>
    participants = [] as any
    learningPathsLoading = false
    learningPathsCount = 0

    get getLearningPathEmployees () {
      return this.learningPath.deployMetadata.employees
    }

    get getLearningPathParticipants (): Array<any> {
      return this.participants
    }

    get getLearningPaths (): Array<LearningPath> {
      return this.learningPaths
    }

    get getLearningPath (): LearningPath {
      return this.learningPath
    }

    get learningPathGroups () {
      return this.learningPath.deployMetadata?.teams || []
    }

    get getLearningPathSettings (): LearningPathSettings {
      return this.learningPath.settings
    }

    get getLearningPathsCount (): number {
      return this.learningPathsCount
    }

    get getLearningPathsLoading (): boolean {
      return this.learningPathsLoading
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_LEARNING_PATHS] (payload) {
      this.context.commit(Mutations.SET_LEARNING_PATHS_LOADING, true)
      const response = await ApiService.get('api/v1/learning-paths', '', payload)
      if (response.status === 200) {
        this.context.commit(Mutations.SET_LEARNING_PATHS_LOADING, false)
      }
      const learningPaths = response.data.payload
      return { learningPaths }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_LEARNING_PATHS_COUNT] (payload) {
      const response = await ApiService.get('api/v1/learning-paths', 'count', payload)

      const learningPathsCount = response.data.payload.count
      return { learningPathsCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_LEARNING_PATH] (learningPathUuid) {
      const response = await ApiService.get('api/v1/learning-paths/' + learningPathUuid)
      const learningPath = response.data.payload
      return { learningPath }
    }

    @Action({ rawError: true })
    async [Actions.API_CREATE_LEARNING_PATH] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/learning-paths', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UPDATE_LEARNING_PATH] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.update('api/v1/learning-paths', payload.uuid, payload.data)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UPDATE_LEARNING_PATH_USER] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.update('api/v1/learning-paths/' + payload.learningPathUuid + '/users', 'update', payload.data)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_DEPLOY_LEARNING_PATH_NOW] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/learning-paths/' + payload.learningPathUuid + '/deploy', payload.data)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_ADD_USERS_TO_LEARNING_PATH] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/learning-paths/' + payload.learningPathUuid + '/users', payload.users)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((response) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_ADD_GROUP_TO_LEARNING_PATH] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/learning-paths/' + payload.learningPathUuid + '/groups', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((response) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_EDIT_GROUP_FROM_LEARNING_PATH] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/learning-paths/' + payload.learningPathUuid + '/groups/' + payload.groupUuid, payload.data)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((response) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UPDATE_LEARNING_PATH_ACTIVITY] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.update('api/v1/learning-paths/' + payload.learningPathUuid + '/activities', 'update', payload.data)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_GET_AVAILABLE_EMPLOYEES] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/learning-paths/' + payload.learningPathUuid + '/employees', '', payload.filter)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_GET_AVAILABLE_EMPLOYEES_COUNT] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/learning-paths/' + payload.learningPathUuid + '/employees/count', '', payload.filter)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_ADD_ACTIVITY_TO_LEARNING_PATH] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/learning-paths/' + payload.learningPathUuid + '/activities', payload.activities)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_REMOVE_ACTIVITY_FROM_LEARNING_PATH] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/learning-paths/' + payload.learningPathUuid + '/activities/remove', payload.activities)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_REMOVE_USERS_FROM_LEARNING_PATH] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/learning-paths/' + payload.learningPathUuid + '/users/remove', payload.users)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_DELETE_GROUP_FROM_LEARNING_PATH] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.delete('api/v1/learning-paths/' + payload.learningPathUuid + '/groups/' + payload.groupUuid)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_DELETE_LEARNING_PATH] (learningPathUuid) {
      return new Promise((resolve, reject) => {
        ApiService.delete('api/v1/learning-paths/' + learningPathUuid)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    @Mutation
    [Mutations.UPDATE_LEARNING_PATH_ADD_GROUP] (group) {
      if (!this.learningPath.deployMetadata.teams) {
        this.learningPath.deployMetadata.teams = []
      }
      this.learningPath.deployMetadata.teams.push(group)
    }

    @Mutation
    [Mutations.UPDATE_LEARNING_PATH_REMOVE_GROUP] (groupUuid) {
      const teams = this.learningPath.deployMetadata?.teams as any

      if (teams) {
        const indexToRemove = teams.findIndex(team => team.groupUuid === groupUuid)

        if (indexToRemove !== -1) {
          // Remove the element without changing the index positions of other elements.
          teams.splice(indexToRemove, 1)

          // Re-index the array.
          this.learningPath.deployMetadata.teams = teams.filter(team => true)
        }
      }
    }

    @Mutation
    [Mutations.UPDATE_LEARNING_PATH_EDIT_GROUP] (payload) {
      const teams = this.learningPath.deployMetadata?.teams as any
      if (teams) {
        const indexToUpdate = teams.findIndex(team => team.groupUuid === payload.groupUuid)
        if (indexToUpdate !== -1) {
          // Update the element at the specified index with the new groupDetails.
          Object.assign(teams[indexToUpdate], payload.groupDetails)
        }
      }
    }

    @Mutation
    [Mutations.SET_LEARNING_PATHS_LOADING] (value) {
      this.learningPathsLoading = value
    }

    @Mutation
    [Mutations.SET_LEARNING_PATH_EMPLOYEES] (value) {
      this.learningPath.deployMetadata.employees = value
    }

    @Mutation
    [Mutations.SET_LEARNING_PATH_TEAMS] (value) {
      this.learningPath.deployMetadata.teams = value
    }

    @Mutation
    [Mutations.SET_LEARNING_PATH_ACTIVITIES] (value) {
      this.learningPath.deployMetadata.activities = value
    }

    @Mutation
    [Mutations.SET_LEARNING_PATH] (value) {
      this.learningPath = value
    }
}
