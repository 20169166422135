import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import { Actions } from '@/store/enums/StoreEnums'
import localforage from 'localforage'
import { MenuComponent } from '@/assets/ts/components'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    meta: {
      guest: false,
      needsAuth: true
    },
    redirect: '/library-manager',
    component: () => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/advisor',
        name: 'advisor',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/AiCreator/AiCreator.vue')
      },
      {
        path: '/console',
        name: 'console',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/Console.vue')
      },
      {
        path: '/session-manager',
        name: 'session-manager',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/mc-admin-session/SessionManager.vue')
      },
      {
        path: '/learning-paths',
        name: 'learning-paths',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/LearningPaths.vue')
      },
      {
        path: '/library-manager',
        name: 'library-manager',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/LibraryManager.vue')
      },
      {
        path: '/employees',
        name: 'employees',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/Employees.vue')
      },
      {
        path: '/employee/:id',
        name: 'employee',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/mc-admin-employees/TheEmployeeDetails.vue'),
        children: [
          {
            path: 'activities/recent',
            name: 'activities-recent',
            meta: {
              guest: false,
              needsAuth: true
            },
            component: () =>
              import('@/views/mc-admin-employees/TheRecentActivities.vue')
          },
          {
            path: 'activities/upcoming',
            name: 'activities-upcoming',
            meta: {
              guest: false,
              needsAuth: true
            },
            component: () =>
              import('@/views/mc-admin-employees/TheUpcomingActivities.vue')
          }
        ]
      },
      {
        path: '/account/details',
        name: 'account-details',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/AccountDetails.vue'),
        children: [
          {
            path: 'overview',
            name: 'overview',
            meta: {
              guest: false,
              needsAuth: true
            },
            component: () =>
              import('@/views/account-details/AccountOverview.vue')
          },
          {
            path: 'billing',
            name: 'billing',
            meta: {
              guest: false,
              needsAuth: true
            },
            component: () =>
              import('@/views/account-details/AccountBilling.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/builder/:id',
    component: () => import('@/views/mc-admin-builder/BuilderLayout.vue'),
    children: [
      {
        path: '/builder/:id',
        name: 'builder',
        meta: {
          guest: false,
          needsAuth: true
        },
        component: () => import('@/views/mc-admin-builder/BuilderWorkspace.vue')
      }
    ]
  },
  {
    path: '/pages/profile/overview/organization',
    name: 'organization-details',
    meta: {
      guest: false,
      needsAuth: true
    },
    component: () =>
      import('@/views/account-details/AccountOverview.vue')
  },
  {
    path: '/auth',
    component: () => import('@/components/page-layouts/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        meta: {
          guest: true,
          needsAuth: false
        },
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignIn.vue')
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        meta: {
          guest: true,
          needsAuth: false
        },
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignUp.vue')
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        meta: {
          guest: true,
          needsAuth: false
        },
        component: () =>
          import('@/views/crafted/authentication/basic-flow/PasswordReset.vue')
      },
      {
        path: '/account-activation',
        name: 'account-activation',
        meta: {
          guest: true,
          needsAuth: false
        },
        component: () =>
          import('@/views/crafted/authentication/basic-flow/AccountActivation.vue')
      },
      {
        path: '/activation/hash/:id',
        name: 'activation',
        meta: {
          guest: true,
          needsAuth: false
        },
        component: () => import('@/views/crafted/authentication/basic-flow/SetPassword.vue')
      },
      {
        path: '/validation/hash/:id',
        name: 'validation',
        meta: {
          guest: true,
          needsAuth: false
        },
        component: () => import('@/views/crafted/authentication/basic-flow/EmailValidation.vue')
      }
    ]
  },
  {
    // the 404 route, when none of the above matches
    path: '/invalid-link',
    name: 'invalid-link',
    component: () => import('@/views/crafted/authentication/basic-flow/InvalidLink.vue')
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/crafted/authentication/Error404.vue')
  },
  {
    path: '/activity-manager',
    name: 'activity-manager',
    component: () => import('@/views/ActivityManager.vue')
  },
  {
    path: '/deploy/:id',
    name: 'deploy',
    component: () => import('@/views/mc-admin-learning-paths/DeployLearningPath.vue')
  },
  // {
  //   // the 404 route, when none of the above matches
  //   path: '/builder-test',
  //   name: 'builder-test',
  //   component: () => import('@/views/mc-admin-builder/BuilderTest.vue')
  // },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    MenuComponent.reinitialization()
  }, 100)
  store.dispatch(Actions.CHECK_TOKEN_EXISTS).then(() => {
    if (to.meta.guest && !['activation', 'sign-in', 'validation', 'sign-up', 'account-activation'].includes(<string>to.name)) {
      // next({ name: 'sign-in' })
      window.location.href = '/sign-in'
      return
    }
    next()
  }).catch(() => {
    if (to.meta.needsAuth) {
      localforage.setItem('intended', to.fullPath)
      window.location.href = '/sign-in'
      // next({ name: 'sign-in' })
      return
    }
    // window.location.href = '/sign-in'
    // window.location.replace('/sign-in')
    next()
  })
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

export default router
