/* eslint-disable */
enum Actions {
    ADD_BODY_CLASSNAME = 'addBodyClassName',
    REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
    ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
    REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
    ADD_CLASSNAME = 'addClassName',
    VERIFY_AUTH = 'verifyAuth',
    LOGIN = 'login',
    LOGOUT = 'logout',
    REGISTER = 'register',
    UPDATE_USER = 'updateUser',
    FORGOT_PASSWORD = 'forgotPassword',
    SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
    SET_PAGE_TITLE_BUTTON_ACTION = 'setPageTitleButtonAction',
    GET_CSRF_COOKIE = 'getCsrfCookie',
    GET_ACCOUNT_BY_UUID = 'getAccountByUuid',
    // Account
    API_ACCOUNT_REQUEST_ACTIVATE = 'apiAccountRequestActivate',
    API_ACCOUNT_CHECK_ACTIVATE = 'apiAccountCheckActivate',
    API_ACCOUNT_SUBMIT_ACTIVATE = 'apiAccountSubmitActivate',
    API_ACCOUNT_EMAIL_SUBMIT_ACTIVATE = 'apiAccountEmailSubmitActivate',
    API_UPDATE_ACCOUNT = 'apiUpdateAccount',
    API_GET_AVATAR_URL = 'apiGetAvatarUrl',

    // Websocket
    CREATE_SOCKET_CONNECTION = 'createSocketConnection',
    ON_SOCKET_CONNECTED = 'onSocketConnected',
    ON_SOCKET_CONNECTION_ERROR = 'onSocketConnectionError',
    ON_SOCKET_RECONNECTED = 'onSocketReconnected',
    ON_SOCKET_DISCONNECTED = 'onSocketDisconnected',
    ON_SOCKET_ERROR = 'onSocketError',
    ON_SERVICE_MESSAGE = 'onServiceMessage',
    ON_PING_RESPONSE = 'onPingResponse',
    PING_REQUEST = 'pingRequest',
    DISCONNECT_SOCKET = 'disconnectSocket',

    // Template
    API_CREATE_ACTIVITY_TEMPLATE = 'apiCreateActivityTemplate',
    API_CREATE_ACTIVITY_TEMPLATE_FROM_PROMPT = 'apiCreateActivityTemplateFromPrompt',
    API_UPDATE_ACTIVITY_TEMPLATE = 'apiUpdateActivityTemplate',
    API_UPLOAD_ACTIVITY_TEMPLATE_IMAGE = 'apiUploadActivityTemplateImage',
    API_UPLOAD_FILE_ACTIVITY_TEMPLATE = 'apiUploadFileActivityTemplate',
    API_DELETE_ACTIVITY_TEMPLATE = 'apiDeleteActivityTemplate',
    API_DELETE_ACTIVITY_TEMPLATE_ATTACHMENT = 'apiDeleteActivityAttachment',
    API_PUBLISH_TEMPLATE = 'apiPublishTemplate',
    API_DUPLICATE_TEMPLATE = 'apiDuplicateTemplate',
    API_MAKE_READY_TEMPLATE = 'apiMakeReadyTemplate',
    API_MAKE_DRAFT_TEMPLATE = 'apiMakeDraftTemplate',
    API_GET_TEMPLATE_MODELS = 'apiGetTemplateModels',
    API_REGENERATE_WIDGET = 'apiRegenerateWidget',
    API_REGENERATE_SECTION = 'apiRegenerateSection',
    API_360_WIDGET_CREATE_SKYBOX_IMAGE = 'api360WidgetCreateSkyboxImage',

    // Employees
    API_DELETE_EMPLOYEE = 'apiDeleteEmployee',
    API_CREATE_EMPLOYEE = 'apiCreateEmployee',
    API_UPDATE_EMPLOYEE = 'apiUpdateEmployee',
    API_GET_EMPLOYEE= 'apiGetEmployee',

    // Learning Path
    API_CREATE_LEARNING_PATH = 'apiCreateLearningPath',
    API_UPDATE_LEARNING_PATH = 'apiUpdateLearningPath',
    API_UPDATE_LEARNING_PATH_USER = 'apiUpdateLearningPathUser',
    API_DEPLOY_LEARNING_PATH_NOW = 'apiDeployLearningPathNow',
    API_ADD_USERS_TO_LEARNING_PATH = 'apiAddUsersToLearningPath',
    API_REMOVE_USERS_FROM_LEARNING_PATH = 'apiRemoveUsersFromLearningPath',
    API_ADD_ACTIVITY_TO_LEARNING_PATH = 'apiAddActivityToLearningPath',
    API_UPDATE_LEARNING_PATH_ACTIVITY = 'apiUpdateLearningPathActivity',
    API_REMOVE_ACTIVITY_FROM_LEARNING_PATH = 'apiRemoveActivityFromLearningPath',
    API_DELETE_LEARNING_PATH = 'apiDeleteLearningPath',
    API_ADD_GROUP_TO_LEARNING_PATH = 'apiAddGroupToLearningPath',
    API_DELETE_GROUP_FROM_LEARNING_PATH = 'apiDeleteGroupFromLearningPath',
    API_GET_AVAILABLE_EMPLOYEES = 'apiGetAvailableEmployees',
    API_GET_AVAILABLE_EMPLOYEES_COUNT = 'apiGetAvailableEmployeesCount',
    API_EDIT_GROUP_FROM_LEARNING_PATH = 'apiEditGroupFromLearningPath',

    // Sessions
    API_DELETE_ACTIVITY_SESSION = 'apiDeleteActivitySession',
    API_GET_ACTIVITY_SESSION = 'apiGetActivitySession',
    // Statistics
    API_GET_METRICS_BY_FILTER = 'apiGetMetricsByFilter',

    // OAuth
    SET_TOKEN = 'setToken',
    CHECK_TOKEN_EXISTS = 'checkTokenExists',
    FETCH_USER = 'fetchUser',
    CLEAR_AUTHENTICATION = 'clearAuthentication',

    // OpenAI
    API_AI_SEARCH_BY_KEYWORDS = 'apiAiSearchByKeywords',
    API_AI_GET_LATEST_MESSAGES = 'apiAiGetLatestMessages',
    API_AI_GET_LATEST_MESSAGES_AI_CREATOR = 'apiAiGetLatestMessagesAiCreator',
    API_AI_CREATE_AI_CREATOR_CHAT_MESSAGE = 'apiAiCreateAiCreatorChatMessage',
    API_AI_CREATE_AI_CREATOR_COURSE = 'apiAiCreateAiCreatorCourse',
    API_AI_GET_COURSE_SUGGESTIONS = 'apiAiGetCourseSuggestions',
    API_AI_RESET_CONVERSATION = 'apiAiResetConversation',
    API_AI_RESET_CONVERSATION_AI_CREATOR = 'apiAiResetConversationAiCreator',
    API_AI_GENERATE_WIDGET = 'apiAiGenerateWidget',

    API_UNSPLASH_SEARCH_PHOTOS = 'apiUnsplashSearchPhotos',
    API_UNSPLASH_FETCH_IMAGE = 'apiUnsplashFetchImage',

    API_ICONFINDER_SEARCH_ICONS = 'apiIconfinderSearchIcons',
    API_UPLOAD_ICONFINDER_IMAGE = 'apiUploadIconfinderImage',
    
    // Public Sessions
    API_PUBLIC_SESSION_CREATE = 'apiPublicSessionCreate',
    
    // Attachments 
    API_UPLOAD_ATTACHMENT = 'apiUploadAttachment',
    
    API_PROCESS_TEXT_TO_SPEECH = 'apiProcessTextToSpeech',
    API_IMPORT_TEMPLATE = 'apiImportTemplate',
    API_DOWNLOAD_TEMPLATE = 'apiDownloadTemplate',

}

enum Mutations {
    SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
    PURGE_AUTH = 'logOut',
    SET_AUTH = 'setAuth',
    SET_USER = 'setUser',
    SET_PASSWORD = 'setPassword',
    SET_ERROR = 'setError',
    SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
    SET_PAGE_TITLE_BUTTON_MUTATION = 'setPageTitleButtonMutation',
    SET_LAYOUT_CONFIG = 'setLayoutConfig',
    RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
    OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
    OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
    SET_ACCOUNT = 'setAccount',
    // Activity Session
    API_SET_ACTIVITY_SESSIONS = 'apiSetActivitySessions',
    API_SET_ACTIVITY_SESSIONS_COUNT = 'apiSetActivitySessionsCount',
    API_SET_ACTIVITY_SESSIONS_IN_PROGRESS_COUNT = 'apiSetActivitySessionsInProgressCount',
    API_SET_ACTIVITY_SESSIONS_SCHEDULED_COUNT = 'apiSetActivitySessionsScheduledCount',
    API_SET_ACTIVITY_SESSIONS_FINISHED_COUNT = 'apiSetActivitySessionsFinishedCount',
    API_SET_ACTIVITY_SESSION_REQUEST_PENDING = 'apiSetActivitySessionRequestPending',
    // Template
    API_SET_TEMPLATE = 'apiSetTemplate',
    SET_ACTIVITY_TEMPLATE_SOLVING_MODE = 'setActivityTemplateSolvingMode',
    SET_ACTIVITY_TEMPLATE_SHOW_GRID_LINES = 'setActivityTemplateShowGridLines',
    SET_ACTIVITY_TEMPLATE_MOBILE_VIEW_MODE = 'setActivityTemplateMobileViewMode',
    SET_ACTIVITY_TEMPLATE_REVEAL_MOBILE_HIDDEN_WIDGETS = 'setActivityTemplateRevealMobileHiddenWidgets',
    SET_ACTIVITY_TEMPLATE = 'setActivityTemplate',
    SET_FILTER_USED_TAGS = 'setFilterUsedTags',
    SET_TEMPLATE_ACTIVE_SECTION_INDEX = 'setTemplateActiveSectionIndex',
    SET_TEMPLATE_ACTIVE_WIDGET_INDEX = 'setTemplateActiveWidgetIndex',
    SET_TEMPLATE_ACTIVE_WIDGET_UUID = 'setTemplateActiveWidgetUuid',
    ADD_WIDGET_TO_COPY = 'addWidgetToCopy',
    RESET_WIDGETS_TO_COPY = 'resetWidgetsToCopy',
    SET_COPIED_WIDGET = 'setCopiedWidget',
    RESET_COPIED_WIDGET = 'resetCopiedWidget',
    REMOVE_WIDGET_FROM_COPY = 'removeWidgetFromCopy',
    ADD_ACTIVE_WIDGETS = 'addActiveWidgets',
    ADD_DISABLED_WIDGET = 'addDisabledWidget',
    CLEAR_DISABLED_WIDGETS = 'clearDisabledWidgets',
    RESET_ACTIVE_WIDGETS = 'resetActiveWidgets',
    REMOVE_ACTIVE_WIDGET = 'removeActiveWidget',
    SET_TEMPLATE_ADD_SECTION = 'setTemplateAddSection',
    SET_TEMPLATE_EDIT_SECTION = 'setTemplateEditSection',
    SET_TEMPLATE_REMOVE_SECTION = 'setTemplateRemoveSection',
    SET_TEMPLATE_ADD_WIDGET = 'setTemplateAddWidget',
    SET_TEMPLATE_EDIT_WIDGET = 'setTemplateEditWidget',
    SET_TEMPLATE_REMOVE_WIDGET_BY_UUID = 'setTemplateRemoveWidgetByUuid',
    UPDATE_REGENERATE_WIDGETS_LIST = 'updateRegenerateWidgetsList',
    SET_TEMPLATE_WIDGET_ORDER_CHANGED = 'setTemplateWidgetOrderChanged',
    API_SET_ACTIVITY_TEMPLATES_REQUEST_PENDING = 'apiSetActivityTemplatesRequestPending',
    REMOVE_ACTIVITY_TEMPLATE = 'removeActivityTemplate',
    REMOVE_ACTIVITY_TEMPLATE_ATTACHMENT = 'removeActivityTemplateAttachment',
    ADD_NEW_TEMPLATE_TO_ALL_TEMPLATES = 'addNewTemplateToAllTemplates',
    UPDATE_ACTIVITY_TEMPLATE_IN_ACTIVITY_TEMPLATES = 'updateActivityTemplateInActivityTemplates',
    UPDATE_ACTIVITY_TEMPLATE_IN_LATEST_TEMPLATES = 'updateActivityTemplateInLatestTemplates',
    INCREASE_READY_TEMPLATES_COUNTER = 'increaseReadyTemplatesCounter',
    DECREASE_READY_TEMPLATES_COUNTER = 'decreaseReadyTemplatesCounter',
    INCREASE_DRAFT_TEMPLATES_COUNTER = 'increaseDraftTemplatesCounter',
    DECREASE_DRAFT_TEMPLATES_COUNTER = 'decreaseDraftTemplatesCounter',
    SET_COMPANY_VISIBILITY_ON_ACTIVITY_TEMPLATES = 'setCompanyVisibilityOnActivityTemplates',
    SET_PUBLIC_VISIBILITY_ON_ACTIVITY_TEMPLATES = 'setPublicVisibilityOnActivityTemplates',
    SET_SEARCH_BY_KEYWORD_FILTER = 'setSearchByKeywordFilter',
    ADD_WIDGET_TO_GROUP = 'addWidgetToGroup',
    REMOVE_WIDGET_FROM_GROUP = 'removeWidgetFromGroup',
    CLEAR_GROUPED_WIDGETS = 'clearGroupedWidgets',
    ADD_HOTSPOT_EXPANDED_WIDGETS = 'addHotspotExpandedWidget',
    REMOVE_HOTSPOT_EXPANDED_WIDGETS = 'removeHotspotExpandedWidget',
    UPDATE_TEMPLATE_MODELS = 'updateTemplateModels',
    ADD_AI_FEEDER_ITEMS = 'addAiFeederItems',
    ADD_POINT_OUT_WIDGET = 'addPointOutWidget',
    CLEAR_POINT_OUT_WIDGET = 'clearPointOutWidget',
    UPDATE_REGENERATE_SECTION_IN_SELECT_MODE = 'updateRegenerateSectionInSelectMode',


    UPDATE_LOCAL_TEMPLATE = 'updateLocalTemplate',
    UPDATE_FILE_UPLOAD_PROGRESS = 'updateFileUploadProgress',
    UPDATE_WIDGET_SET_IMAGE = 'updateWidgetSetImage',
    UPDATE_SECTION_SET_IMAGE = 'updateSectionSetImage',
    UPDATE_WIDGET_ADD_SCENE = 'updateWidgetAddScene',
    UPDATE_WIDGET_ADD_HOTSPOT_TO_SCENE = 'updateWidgetAddHotspotToScene',
    UPDATE_WIDGET_REMOVE_HOTSPOT = 'updateWidgetRemoveHotspot',
    UPDATE_WIDGET_REMOVE_SCENE = 'updateWidgetRemoveScene',
    UPDATE_WIDGET_EDIT_HOTSPOT = 'updateWidgetEditHotspot',
    SET_SELECTED_SCENE_FOR_WIDGET = 'setSelectedSceneForWidget',
    SET_SELECTED_HOTSPOT_IN_SCENE = 'setSelectedHotspotInScene',
    UPDATE_WIDGET_ADD_FILE = 'updateWidgetAddFile',
    UPDATE_WIDGET_EDIT_WIDGET = 'updateWidgetEditWidget',
    UPDATE_WIDGET_EDIT_WIDGET_STREAM = 'updateWidgetEditWidgetStream',
    UPDATE_IS_HOTSPOT_IN_SELECT_MODE = 'updateIsHotspotInSelectMode',
    UPDATE_HOTSPOT_WIDGET_IN_SELECT_MODE = 'updateHotspotWidgetInSelectMode',
    REMOVE_WIDGETS_FROM_SELECTED_HOTSPOTS = 'removeWidgetsFromSelectedHotspots',
    ADD_WIDGETS_TO_SELECTED_HOTSPOTS = 'addWidgetsToTheSelectedHotspots',
    // Websocket
    SET_LAST_PING_REQUEST_ID = 'setLastPingRequestId',
    SET_PING_TIMER = 'setPingTimer',
    CLEAR_TIMEOUT_PING_TIMER = 'clearTimeoutPingTimer',
    SET_ADMIN_ERRORS = 'setAdminErrors',
    SET_SHOW_MULTIPLE_WINDOWS_ERROR = 'setShowMultipleWindowsError',
    SET_SHOW_WEBSOCKET_ERROR = 'setShowWebsocketError',

    // Employees
    SET_EMPLOYEES_LOADING = 'setEmployeesLoading',
    REMOVE_EMPLOYEE = 'removeEmployee',
    SET_EMPLOYEE = 'setEmployee',
    API_GET_EMPLOYEE = 'getEmployee',

    // Learning paths
    SET_LEARNING_PATHS_LOADING = 'setLearningPathsLoading',
    SET_LEARNING_PATH_EMPLOYEES = 'setLearningPathParticipants',
    SET_LEARNING_PATH_TEAMS = 'setLearningPathTeams',
    SET_CHECKED_EMPLOYEES = 'setCheckedEmployees',
    SET_LEARNING_PATH_ACTIVITIES = 'setLearningPathActivities',
    SET_LEARNING_PATH = 'setLearningPath',
    UPDATE_LEARNING_PATH_ADD_GROUP = 'updateLearningPathAddGroup',
    UPDATE_LEARNING_PATH_REMOVE_GROUP = 'updateLearningPathRemoveGroup',
    UPDATE_LEARNING_PATH_EDIT_GROUP = 'updateLearningPathEditGroup',
    

    // Statistics
    SET_EMPLOYEE_STATISTICS_FOR_SESSIONS = 'setEmployeeStatisticsForSessions',
    SET_EMPLOYEE_STATISTICS_FOR_SESSIONS_AVERAGE = 'setEmployeeStatisticsForSessionsAverage',

    // OAuth
    STORE_TOKEN = 'storeToken',
    SET_AUTHENTICATED = 'setAuthenticated',
    SET_ACCOUNT_DATA = 'setAccountData',
    SET_FORCE_LOGOUT = 'setForceLogout',

    //OpenAI
    SET_LATEST_MESSAGES = 'setLatestMessages',
    SET_LATEST_MESSAGES_AI_CREATOR = 'setLatestMessagesAiCreator',
    ADD_MESSAGE_TO_LATEST_MESSAGES = 'addMessageToLatestMessages',
    ADD_MESSAGE_TO_LATEST_AI_CREATOR_MESSAGES = 'addMessageToLatestAiCreatorMessages',
    REMOVE_MESSAGE_BY_MESSAGE_ID = 'removeMessageByMessageId',
    UPDATE_OPEN_AI_MESSAGE = 'updateOpenAiMessage',
    
    SET_ATTACHMENT_UPLOAD_PROGRESS = 'setAttachmentUploadProgress'
}

enum MutationsActions {
    // Activity Session
    API_GET_MUTATE_ACTIVITY_SESSIONS = 'apiGetMutateActivitySessions',
    API_GET_MUTATE_ACTIVITY_SESSIONS_COUNT = 'apiGetMutateActivitySessionsCount',
    API_GET_MUTATE_ACTIVITY_SESSIONS_IN_PROGRESS_COUNT = 'apiGetMutateActivitySessionsInProgressCount',
    API_GET_MUTATE_ACTIVITY_SESSIONS_SCHEDULED_COUNT = 'apiGetMutateActivitySessionsScheduledCount',
    API_GET_MUTATE_ACTIVITY_SESSIONS_FINISHED_COUNT = 'apiGetMutateActivitySessionsFinishedCount',
    // Template
    API_GET_MUTATE_TEMPLATE = 'apiGetMutateTemplate',
    API_GET_MUTATE_ACTIVITY_TEMPLATES = 'apiGetMutateActivityTemplates',
    API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT = 'apiGetMutateActivityTemplatesCount',
    API_GET_MUTATE_ALL_TEMPLATES_COUNT = 'apiGetMutateAllTemplatesCount',
    API_GET_MUTATE_READY_TEMPLATES_COUNT = 'apiGetMutateReadyTemplatesCount',
    API_GET_MUTATE_DRAFT_TEMPLATES_COUNT = 'apiGetMutateDraftTemplatesCount',
    API_GET_MUTATE_AVAILABLE_TAGS = 'apiGetMutateAvailableTags',


    // Company
    API_GET_MUTATE_COMPANY_BY_UUID = 'apiGetMutateCompanyByUuid',

    API_GET_MUTATE_LATEST_ACTIVITY_TEMPLATES = 'apiGetMutateLatestActivityTemplates',

    // Learning Paths
    API_GET_MUTATE_LEARNING_PATH = 'apiGetMutateLearningPath',
    API_GET_MUTATE_LEARNING_PATHS = 'apiGetMutateLearningPaths',
    API_GET_MUTATE_LEARNING_PATHS_COUNT = 'apiGetMutateLearningPathsCount',

    // Employees
    API_GET_MUTATE_EMPLOYEES = 'apiGetMutateEmployees',
    API_GET_MUTATE_EMPLOYEES_COUNT = 'apiGetMutateEmployeesCount',
    API_GET_MUTATE_EMPLOYEE = 'apiGetMutateEmployee',

    // Websocket
    SET_MUTATE_ACTIVE_SOCKET = 'setMutateActiveSocket',

    API_GET_MUTATE_METRICS_BY_FILTER = 'apiGetMutateMetricsByFilter'

}

export {Actions, Mutations, MutationsActions}
