import { App } from 'vue'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import VueAxios from 'vue-axios'
import qs from 'qs'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
// import router from '@/router/clean'
import { isEmpty } from 'lodash'
import store from '@/store'
import { Mutations } from '@/store/enums/StoreEnums'

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init (app: App<Element>) {
    ApiService.vueInstance = app
    ApiService.vueInstance.use(VueAxios, axios)
    ApiService.vueInstance.axios.defaults.headers = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0'
    }
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL
    ApiService.vueInstance.axios.defaults.withCredentials = true
  }

  public static setAuthorization (token) {
    if (isEmpty(token)) {
      ApiService.vueInstance.axios.defaults.headers.Authorization = null
      return
    }

    ApiService.vueInstance.axios.defaults.headers.Authorization = 'Bearer ' + token
  }

  public static getAuthorizationHeader (): string {
    return ApiService.vueInstance.axios.defaults.headers.Authorization
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader (): void {
    ApiService.vueInstance.axios.defaults.headers.common.Accept =
      'application/json'
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query (
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse | any> {
    return ApiService.vueInstance.axios.get(resource, params).catch(({ response }) => {
      if (response.status === 401) {
        store.commit(Mutations.SET_FORCE_LOGOUT, true)
      } else { // show general error messages
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }
    })
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: object
   * @param config
   * @returns Promise<AxiosResponse>
   */
  public static get (
    resource: string,
    slug = '' as string,
    params = {} as object,
    config = {} as object|any
  ): Promise<AxiosResponse | any> {
    const responseType = 'responseType' in config ? config.responseType : 'json'
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`, {
      responseType: responseType,
      params: params,
      paramsSerializer: params => {
        return qs.stringify(params)
      },
      onDownloadProgress: config?.onDownloadProgress
    }).catch(({ response }) => {
      if (response.status === 401) {
        store.commit(Mutations.SET_FORCE_LOGOUT, true)
      } else { // show general error messages
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }
    })
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @param config
   * @returns Promise<AxiosResponse>
   */
  public static post (
    resource: string,
    params: AxiosRequestConfig,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse | any> {
    return new Promise((resolve, reject) => {
      ApiService.vueInstance.axios.post(`${resource}`, params, config)
        .then((response) => {
          resolve(response)
        }).catch(({ response }) => {
          if (response.status === 401) {
            // will redirect to sign-in
            store.commit(Mutations.SET_FORCE_LOGOUT, true)
          } else if (response.status === 500) {
            reject(response)
          } else {
            // reject the error so that it can be handled elsewhere
            if (response.data.error.code.includes('.ready.') ||
                response.data.error.code.includes('.draft.') ||
                response.data.error.code.includes('invalid.parameter') ||
                response.data.error.code.includes('learningPath.update') ||
                response.data.error.code.includes('account.could.not.be.updated') ||
                response.data.error.code.includes('account.login.failed') ||
                response.data.error.code.includes('account.not.activated') ||
                response.data.error.code.includes('account.check.') ||
                response.data.error.code.includes('openai.could.not.generate.widget')
            ) {
              reject(response)
            } else {
              Swal.fire({
                text: response.data.error.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Try again',
                customClass: {
                  confirmButton: 'btn fw-bold btn-light-danger'
                }
              })
            }
          }
        })
    })
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @param config
   * @returns Promise<AxiosResponse>
   */
  public static update (
    resource: string,
    slug: string,
    params: AxiosRequestConfig,
    config: AxiosRequestConfig = {}
  ): Promise<AxiosResponse | any> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params, config).catch(({ response }) => {
      if (response.status === 401) {
        store.commit(Mutations.SET_FORCE_LOGOUT, true)
      } else { // show general error messages
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }
    })
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put (
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse | any> {
    return ApiService.vueInstance.axios.put(`${resource}`, params).catch(({ response }) => {
      if (response.status === 401) {
        store.commit(Mutations.SET_FORCE_LOGOUT, true)
      } else { // show general error messages
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }
    })
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static delete (
    resource: string,
    params: AxiosRequestConfig = {} as object
  ): Promise<AxiosResponse | any> {
    return ApiService.vueInstance.axios.delete(`${resource}`, params).catch(({ response }) => {
      if (response.status === 401) {
        store.commit(Mutations.SET_FORCE_LOGOUT, true)
      } else { // show general error messages
        Swal.fire({
          text: response.data.error.message,
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Try again',
          customClass: {
            confirmButton: 'btn fw-bold btn-light-danger'
          }
        })
      }
    })
  }
}

export default ApiService
