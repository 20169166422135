
import ApiService from '@/core/services/ApiService'

import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { Module, Action, VuexModule, Mutation } from 'vuex-module-decorators'
import store from '@/store'
import { watch } from 'vue'

@Module
export default class OpenAiModule extends VuexModule {
  constructor (store: any) {
    super(store)

    // Watch for changes in generateTemplateFromAi and update localStorage
    watch(() => this.generateTemplateFromAi, (newValue) => {
      console.log('udpates here')
      localStorage.setItem('generateTemplateFromAi', JSON.stringify(newValue))
    })
  }

  generateTemplateFromAi = [] || null as any

  latestMessages = [] as any;
  latestAiCreatorMessages = [] as any;

  get getLatestMessages () {
    return this.latestMessages
  }

  get getLatestAiCreatorMessages () {
    return this.latestAiCreatorMessages
  }

  get getGenerateTemplateFromAi () {
    // Check if data is available in localStorage
    const cachedData = localStorage.getItem('generateTemplateFromAi')

    if (cachedData) {
      // If data is available, parse and return it
      store.commit('setGenerateTemplateFromAi', JSON.parse(cachedData))
      return this.generateTemplateFromAi
    } else {
      store.dispatch(Actions.CHECK_TOKEN_EXISTS).then((token) => {
        ApiService.setAuthorization(token)
      }).then(() => {
        store.dispatch('fetchGenerateTemplateFromAi')
      })
      // this.fetchGenerateTemplateFromAi()
      // Return the current state while waiting for the API response
      return this.generateTemplateFromAi
    }
  }

  @Mutation
  setGenerateTemplateFromAi (data: any) {
    localStorage.setItem('generateTemplateFromAi', JSON.stringify(data))
    this.generateTemplateFromAi = data
  }

  // Action to trigger data fetching if needed
  @Action({ commit: 'setGenerateTemplateFromAi', rawError: true })
  async fetchGenerateTemplateFromAi () {
    try {
      const response = await ApiService.get('api/v1/openai/ai-creator/template/prompt/create/status') // Update with your actual keywords
      console.log('response fetchGenerateTemplateFromAi', response)
      const data = !response.data.payload ? [] : response.data.payload.template// Adjust according to your API response structure

      // Update Vuex state
      store.commit('setGenerateTemplateFromAi', data)

      // Save data to localStorage for future use
      localStorage.setItem('generateTemplateFromAi', JSON.stringify(data))

      return data
    } catch (error) {
      console.error('Error fetching data from API:', error)
      throw error
    }
  }

  @Action({ rawError: true })
  async [Actions.API_AI_SEARCH_BY_KEYWORDS] (keywords) {
    return new Promise((resolve, reject) => {
      const params = { search: keywords } as any
      ApiService.post('api/v1/openai/search', params)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.API_AI_CREATE_AI_CREATOR_CHAT_MESSAGE] (keywords) {
    return new Promise((resolve, reject) => {
      // const params = { search: keywords } as any
      ApiService.post('api/v1/openai/ai-creator/search', keywords)
        .then(({ data }) => {
          this.context.commit(Mutations.ADD_MESSAGE_TO_LATEST_AI_CREATOR_MESSAGES, data.payload)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.API_AI_CREATE_AI_CREATOR_COURSE] (keywords) {
    return new Promise((resolve, reject) => {
      // const params = { search: keywords } as any
      ApiService.post('api/v1/openai/ai-creator/template/create', keywords)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.API_AI_GENERATE_WIDGET] (params: any) {
    return new Promise((resolve, reject) => {
      const payload = { search: params.search, widget: params.widget, textCardSubtype: params.textCardSubtype } as any
      ApiService.post('api/v1/openai/generate', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.API_AI_RESET_CONVERSATION] () {
    return new Promise((resolve, reject) => {
      ApiService.post('api/v1/openai/conversations/reset', [] as any)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LATEST_MESSAGES, [])
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.API_AI_RESET_CONVERSATION_AI_CREATOR] () {
    return new Promise((resolve, reject) => {
      ApiService.post('api/v1/openai/conversations/ai-creator/reset', { entity: 'ai_creator' } as any)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LATEST_MESSAGES_AI_CREATOR, [])
          this.context.commit(Mutations.ADD_MESSAGE_TO_LATEST_AI_CREATOR_MESSAGES, data.payload)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.API_AI_GET_LATEST_MESSAGES] () {
    return new Promise((resolve, reject) => {
      ApiService.post('api/v1/openai/getLatestMessages', [] as any)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LATEST_MESSAGES, data.payload.reverse())
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.API_AI_GET_LATEST_MESSAGES_AI_CREATOR] () {
    return new Promise((resolve, reject) => {
      ApiService.post('api/v1/openai/getLatestMessages', { entity: 'ai_creator' } as any)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LATEST_MESSAGES_AI_CREATOR, data.payload)
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @Action({ rawError: true })
  async [Actions.API_AI_GET_COURSE_SUGGESTIONS] () {
    return new Promise((resolve, reject) => {
      ApiService.get('api/v1/openai/ai-creator/suggestions')
        .then(({ data }) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  @Mutation
  [Mutations.SET_LATEST_MESSAGES] (messages) {
    this.latestMessages = messages
  }

  @Mutation
  [Mutations.SET_LATEST_MESSAGES_AI_CREATOR] (messages) {
    this.latestAiCreatorMessages = messages
  }

  @Mutation
  [Mutations.ADD_MESSAGE_TO_LATEST_AI_CREATOR_MESSAGES] (message) {
    this.latestAiCreatorMessages.push(message)
  }

  @Mutation
  [Mutations.ADD_MESSAGE_TO_LATEST_MESSAGES] (message) {
    this.latestMessages.unshift(message)
  }

  @Mutation
  [Mutations.REMOVE_MESSAGE_BY_MESSAGE_ID] (messageId) {
    this.latestAiCreatorMessages = this.latestAiCreatorMessages.filter(message => message.messageId !== messageId)
  }

  @Mutation
  [Mutations.UPDATE_OPEN_AI_MESSAGE] (updatedMessage) {
    const index = this.latestAiCreatorMessages.findIndex(message => message.messageId === updatedMessage.messageId)
    if (index !== -1) {
      this.latestAiCreatorMessages[index] = updatedMessage
    }
  }
}
